<template>
	<div>

    <div class="x-contact-us">
            <a href="https://lin.ee/U5HSsdB" class="-line-contact-button-container js-line-contact-hover" target="_blank" rel="noopener">
            <img src="../assets/images/ic-contact-line.png" class="-ic" alt="champber1688 รูปโลโก้ Line png">
            <div class="-content-wrapper">
                <div class="-content-inside">
                    <span>ฝ่ายบริการลูกค้า<br>ดูแลคุณ 24 ชั่วโมง</span>
                </div>
            </div>
        </a>
    </div>
    <div id="account-actions" class="-logged" v-if="user.key">
        <div class="x-button-actions" id="account-actions-mobile">
          <div class="-outer-wrapper">
            <div class="-left-wrapper">
              <router-link to="/" class="-item-wrapper -promotion">
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/home.png"
                  />
                  <img
                    src="../assets/images/home.png"
                    class="-ic-img"
                    alt="SHIBA888 รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">หน้าแรก</span>
              </router-link>
              <a
                href="javascript:void(0);"
                class="-item-wrapper -deposit js-account-approve-aware"
                data-toggle="modal"
                data-target="#depositModal"
                @click="checkPromotions()"
              >
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/icdeposit.png"
                  />
                  <img
                    src="../assets/images/icdeposit.png"
                    class="-ic-img"
                    alt="SHIBA888 รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">ฝากเงิน</span>
              </a>

            </div>

            <router-link to="/provider" class="-center-wrapper">
              <div
                class="-selected"
                style="background-image: url('https://firebasestorage.googleapis.com/v0/b/champber1688.appspot.com/o/images%2FChanber-join.png?alt=media&token=6001a634-0745-4068-b5eb-d291143a131f')"
              >
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  data-src="../assets/images/shiba888-curve-top.png"
                  class="-top-curve lazyload"
                  alt="SHIBA888 คาสิโนออนไลน์ระดับโลก รวม คาสิโน บาคาร่าสด สล็อต และกีฬา"
                />
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  data-src="../assets/images/shiba888-curve-bottom.png"
                  class="-bottom-curve lazyload"
                  alt="SHIBA888 คาสิโนออนไลน์ระดับโลก รวม คาสิโน บาคาร่าสด สล็อต และกีฬา"
                />
              </div>
            </router-link>
            <div
              class="-fake-center-bg-wrapper"
              style="position: absolute;z-index: 1;width: 100%;height: 50px;overflow: hidden;left: 0;bottom: 0;"
            >
              <svg viewbox="-10 -1 30 12">
                <defs>
                  <linearGradient
                    id="rectangleGradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="20%" stop-color="#393854" />
                    <stop offset="100%" stop-color="#100e1e" />
                  </linearGradient>
                </defs>
                <path
                  d="M-10 -1 H30 V12 H-10z M 5 5 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0z"
                />
              </svg>
            </div>

            <div class="-right-wrapper">
				      <a
                href="javascript:void(0);"
                class="-item-wrapper -withdraw js-account-approve-aware"
                data-toggle="modal"
                data-target="#withdrawModal"
                @click="moveCreditToWallet()"
              >
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/icwithdraw.png"
                  />
                  <img
                    src="../assets/images/icwithdraw.png"
                    class="-ic-img"
                    alt="SHIBA888 รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">ถอนเงิน</span>
              </a>
              <a
                href="https://lin.ee/U5HSsdB"
                class="-item-wrapper -line"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/shiba888-ic-mobile-left-1.png"
                  />
                  <img
                    src="../assets/images/shiba888-ic-mobile-left-1.png"
                    class="-ic-img"
                    alt="SHIBA888 รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">ติดต่อเรา</span>
              </a>
            </div>
            <div class="-fully-overlay js-footer-lobby-overlay"></div>
          </div>
        </div>
      </div>

      <footer>
			<div class="footer-width-fixer">		<div data-elementor-type="wp-post" data-elementor-id="274" class="elementor elementor-274" data-elementor-post-type="elementor-hf">
									<section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-top-section elementor-element elementor-element-2f2ddc41 linecuttop01 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2f2ddc41" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2e9347d2" data-id="2e9347d2" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-05caac2 elementor-widget elementor-widget-image" data-id="05caac2" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
															<img width="600" height="432" src="../assets/images/logo.png" class="attachment-large size-large wp-image-6174" alt="" loading="lazy"  sizes="(max-width: 600px) 100vw, 600px">															</div>
				</div>
				<div class="elementor-element elementor-element-0cfbffa elementor-widget elementor-widget-text-editor" data-id="0cfbffa" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
							<p style="color: #fff;font-size: 15px;">เป็นผู้ให้บริการคาสิโนออนไลน์ที่ใหญ่ที่สุดในปี 2024 มีการพัฒนาและแก้ไขระบบอย่างดีที่สุดด้วยทีมงานผู้เชี่ยวชาญ ที่คอยช่วยเหลือนักพนันตลอดการเดิมพัน 24 ชั่วโมง ทำให้ท่านเพลิดเพลินได้เต็มที่ เมื่อท่านเกิดปัญหาใดๆ อีกทั้งเราคือผู้ให้บริการพนันออนไลน์ ที่มีรูปแบบของเกมให้ท่านได้เลือกรับความบันเทิงอย่างหลากหลาย และนอกจากนี้ท่านก็จะได้พบกับโปรโมชั่นสุดคุ้มแบบจัดเต็ม มอบค่าตอบแทนจากการลงทุน ในแบบที่ท่านไม่เคยได้จากที่ไหนมาก่อน</p>						</div>
				</div>
				<section data-particle_enable="false" data-particle-mobile-disabled="false" class="elementor-section elementor-inner-section elementor-element elementor-element-2266617 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2266617" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-eb8eed1" data-id="eb8eed1" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-eeb24b1 elementor-widget elementor-widget-text-editor" data-id="eeb24b1" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
							<p style="color: #fff">ธนาคารที่เราให้บริการ</p>						</div>
				</div>
				<div class="elementor-element elementor-element-4a3d3bf elementor-widget elementor-widget-image" data-id="4a3d3bf" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
															<img width="1024" height="198" src="../assets/images/lowres-ft-banklist.png" class="attachment-large size-large wp-image-5474" alt="" loading="lazy" sizes="(max-width: 1024px) 100vw, 1024px">															</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4ee7ad9" data-id="4ee7ad9" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-981cf33 elementor-widget elementor-widget-text-editor" data-id="981cf33" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
							<p style="color: #fff">พาร์ทเนอร์ที่ร่วมกับเรา</p>						</div>
				</div>
				<div class="elementor-element elementor-element-04dd2da elementor-widget elementor-widget-image" data-id="04dd2da" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
															<img width="1024" height="302" src="../assets/images/lowres-ft-provider.png" class="attachment-large size-large wp-image-5475" alt="" loading="lazy" sizes="(max-width: 1024px) 100vw, 1024px">															</div>
				</div>
					</div>
		</div>
							</div>
		</section>
				<div class="elementor-element elementor-element-3e81cc3 elementor-widget elementor-widget-text-editor" data-id="3e81cc3" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
							<p style="color: #fff">พร้อมระบบฝาก-ถอน AUTO อัตโนมัติที่รวดเร็ว และ ทันสมัยที่สุดในปัจจุบัน</p>						</div>
				</div>
				<div class="elementor-element elementor-element-2a71aa0 elementor-widget elementor-widget-image" data-id="2a71aa0" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
															<img width="937" height="67" src="../assets/images/e4c5a43354.png" class="attachment-large size-large wp-image-6705" alt="" loading="lazy" sizes="(max-width: 937px) 100vw, 937px">															</div>
				</div>
				<div class="elementor-element elementor-element-1a0b089 elementor-widget elementor-widget-text-editor" data-id="1a0b089" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
							<p style="color: #fff">Copyright ©2024, All Rights Reserved. MAXICANO.CLOUD</p>						</div>
				</div>
					</div>
		</div>
							</div>
		</section>
							</div>
		</div>		</footer>

	</div>
</template>
<script>
import firebase from 'firebase/app'
import router from './../router'
export default {
  name: 'Footer',
  computed: {
    user () {
      return this.$store.state.user.user
    },
    lastbonus () {
      return this.$store.state.user.lastbonus
    }
  },
  methods: {
    getUserId () {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        return _user
      } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/')
      }
    },
    async moveCreditToWallet () {
      this.$store.commit('user/SET_LOADING', true)
      const user = this.getUserId()
      this.$store.dispatch('user/refreshCreditAll').finally(res => {
        this.$store.dispatch('user/getLastBonus', user).finally(res => {
          this.$store.commit('user/SET_LOADING', false)
          if (this.lastbonus.turncredit) {
            this.$store.commit('user/SET_ALERT_WITHDRAWALL', true)
          }
        }).catch(err => { console.error(err) })
      }).catch(err => { console.error(err); this.fa_spin = false })
    },
    async checkPromotions () {
      this.$store.commit('user/SET_LOADING', true)
      this.$store.dispatch('user/checkPromotions').finally(res => { this.$store.commit('user/SET_LOADING', false) }).catch(err => { console.error(err) })
    }
  }
}
</script>
<style scoped>
#account-actions-mobile .-outer-wrapper {
    display: none;
}
@media (max-width: 500px) {
#account-actions-mobile .-outer-wrapper {
    display: flex;
    align-items: flex-end;
}

#account-actions-mobile .-left-wrapper {
    border-top-left-radius: 10px;
    border-top-right-radius: 22px;
}

#account-actions-mobile .-left-wrapper, #account-actions-mobile .-right-wrapper {
    display: flex;
    align-items: flex-end;
    flex: 1;
    z-index: 2;
    height: 70px;
    padding-bottom: 6px;
    background: linear-gradient(180deg,#007BFF,#50ABE7);
    transition: filter .2s;
}

#account-actions-mobile .-right-wrapper {
    border-top-right-radius: 10px;
    border-top-left-radius: 22px;
}

#account-actions-mobile .-left-wrapper, #account-actions-mobile .-right-wrapper {
    display: flex;
    align-items: flex-end;
    flex: 1;
    z-index: 2;
    height: 70px;
    padding-bottom: 6px;
    background: linear-gradient(180deg,#007BFF,#50ABE7);
    transition: filter .2s;
}

#account-actions-mobile .-center-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
    width: 84px;
    height: 74px;
    margin: 0 10px 16px;
    padding-bottom: 5px;
    color: #fff;
    /* background: linear-gradient(180deg,#ffe872 25%,#ffa021); */
    /* border-radius: 50%; */
    /* box-shadow: 0 0 10px hsl(0deg 0% 100% / 40%); */
}

#account-actions-mobile .-item-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
}

.static-text {
    margin-left: 8px;
    margin-right: 8px;
}
}
#account-actions-mobile .-center-wrapper .-selected {
    transition: all .4s;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: 90px;
    background-position: -2px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
}
.footer-width-fixer {
    width: 100%;
}

/* Container fix for genesis themes */

.ehf-template-genesis.ehf-header .site-header .wrap,
.ehf-template-genesis.ehf-footer .site-footer .wrap,
.ehf-template-generatepress.ehf-header .site-header .inside-header {
    width: 100%;
    padding: 0;
    max-width: 100%;
}

/* Container fix for generatepress theme */

.ehf-template-generatepress.ehf-header .site-header,
.ehf-template-generatepress.ehf-footer .site-footer {
    width: 100%;
    padding: 0;
    max-width: 100%;
    background-color: transparent !important; /* override generatepress default white color for header */
}

.bhf-hidden {
    display: none
}

/* Fix: Header hidden below the page content */
.ehf-header #masthead {
	z-index: 99;
    position: relative;
}
</style>
